import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModuleBackend } from '@enums/index';
import { GenericCrudService } from '@common/classes/generic-crud.service';


@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService extends GenericCrudService<any> {
  private showCreateRequest$: BehaviorSubject<boolean>;
  private showCreatePurchase$: BehaviorSubject<boolean>;

  constructor() {
    super(ModuleBackend.PRODUCT);
    this.showCreateRequest$ = new BehaviorSubject(false);
    this.showCreatePurchase$ = new BehaviorSubject(false);
  }

  setshowCreateRequest(show: boolean = false) { this.showCreateRequest$.next(show); }
  getshowCreateRequest() { return this.showCreateRequest$.getValue(); }
  setshowCreatePurchase(show: boolean = false) { this.showCreatePurchase$.next(show); }
  getshowCreatePurchase() { return this.showCreatePurchase$.getValue(); }
}
