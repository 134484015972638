<ion-header [translucent]="true" class="ion-no-border">
  <ion-toolbar mode="md" class="header">

    <ion-grid>
      <ion-row size="12">
        <ion-col size="3" size-xs="1" size-md="1" size-lg="3">
          <ion-img src="/assets/img/Logo-Light.svg" class="logo-header"></ion-img>
          <ion-title>
            Name Company
          </ion-title>
        </ion-col>
        <ion-col size="6" size-xs="0" size-md="0" size-lg="6">
          <ion-title *ngIf="activeRoute">
            <!-- <span>Inventario / Productos / </span>  -->
            <span *ngFor="let item of activeRoute; let i= index" [routerLink]="item?.routePage">{{ i !== 0 ? ' / '+item?.title : item?.title }}</span>
          </ion-title>
        </ion-col>
        <ion-col size="3" size-xs="11" size-md="11" size-lg="3">
          <ion-title>{{ infoClientSelected?.businessName }}</ion-title>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-buttons slot="end">
      <ion-menu-button class="margin-left-md" slot="end" menuId="main-menu"></ion-menu-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>
